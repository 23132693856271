import React, { FC, useEffect, useState } from 'react';

import { IndexBar, List, SearchBar } from 'antd-mobile';
import { ClientJson } from './types';
import { Link } from 'react-router-dom';
import { useNavbar } from '../hooks/use-navbar';

const Search: FC = () => {
  const [clients, setClients] = useState<ClientJson[]>([]);
  const navbar = useNavbar();
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (navbar) {
      navbar.setTitle('Rercherche');
      navbar.setBackArrow(false);
    }
  }, [navbar]);

  useEffect(() => {
    let isMounted = true; // note mutable flag

    fetch(`${process.env.REACT_APP_BACKEND_HOST}/api2/clients`, { headers: { version: '' + process.env.REACT_APP_VERSION } })
      .then((res) => res.json())
      .then((data) => {
        if (isMounted) setClients(data.filter((c: ClientJson) => !c.archived));
      })
      .catch(console.log);
    return () => {
      isMounted = false;
    };
  }, [navbar]);

  const clientsFiltered = clients && clients.filter((c) => search.length && c.name.toLowerCase().includes(search?.toLowerCase()));

  const charCodeOfA = 'A'.charCodeAt(0);

  const groups = Array(26)
    .fill('')
    .map((_, i) => ({
      title: String.fromCharCode(charCodeOfA + i),
      items: clientsFiltered.filter((c) => c.name[0] === String.fromCharCode(charCodeOfA + i))
    }))
    .filter((g) => g.items.length > 0);
  return (
    <>
      <SearchBar placeholder="Client" style={{ '--background': '#ffffff' }} onChange={(val) => setSearch(val)} />

      <IndexBar sticky={true} style={{ background: '#f5f5f5' }}>
        {groups.map((group) => {
          const { title, items } = group;
          return (
            <IndexBar.Panel index={title} title={`${title}`} key={`${title}`}>
              <List mode={'card'}>
                {items.map((item, index) => (
                  <Link key={index} to={`/client/${item.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <List.Item>{item.name}</List.Item>
                  </Link>
                ))}
              </List>
            </IndexBar.Panel>
          );
        })}
      </IndexBar>
    </>
  );
};
export default Search;
