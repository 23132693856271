import React, { FC, useEffect, useRef } from 'react';

import { useNavbar } from '../hooks/use-navbar';

import './SignaturePad.css';

const Sign: FC = () => {
  const navbar = useNavbar();

  useEffect(() => {
    if (navbar) {
      navbar.setTitle('Signature');
      navbar.setBackArrow(false);
      navbar.setRight(false);
    }
  }, [navbar]);

  useEffect(() => {
    // let isMounted = true; // note mutable flag

    // fetch(`${process.env.REACT_APP_BACKEND_HOST}/api2/clients`)
    //   .then((res) => res.json())
    //   .then((data) => {
    //     if (isMounted) setClients(data);
    //   })
    //   .catch(console.log);
    return () => {
      // isMounted = false;
    };
  }, [navbar]);

  const ref = useRef(null);

  return (
    // <List mode={'card'}>
    //   {/* {items.map((item, index) => (
    //     <Link key={index} to={`/client/${item.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
    //       <List.Item>{item.name}</List.Item>
    //     </Link>
    //   ))} */}
    // </List>
    // <SignaturePad canvasProps={{ className: 'sigPad' }} ref={ref} />
    <></>
  );
};
export default Sign;
